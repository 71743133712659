<template>
  <div>
    <div class="blocks-container">
      <div v-for="(image, index) in allImages" class="block-item" :style="{ 'background-image': 'url(' + image + ')' }" :key="index" @click="removeImage(index)">
        <div class="remove-image">&times;</div>
      </div>
      <label class="block" for="input-file" v-if="maxImages === null || allImages.length < maxImages">
        <svg xmlns="http://www.w3.org/2000/svg" width="60.249" height="62.184" viewBox="0 0 60.249 62.184">
          <g id="Group_43" data-name="Group 43" transform="translate(2.5 2.5)">
            <line id="Line_1" data-name="Line 1" y2="57.184" transform="translate(27.624)" fill="none" stroke="#bebebe" stroke-linecap="round" stroke-width="3"/>
            <line id="Line_2" data-name="Line 2" y2="55.249" transform="translate(55.249 28.592) rotate(90)" fill="none" stroke="#bebebe" stroke-linecap="round" stroke-width="3"/>
          </g>
        </svg>
      </label>
    </div>
    <input id="input-file" type="file" @change="add">
  </div>
</template>

<script>
export default {
  name: "AddImages",
  props: {
    maxImages: { required: false, default: () => null },
    files: { required: true }
  },
  mounted() {
    console.log(this.files)
  },
  computed: {
    allImages: function() {
      return this.files.map(file => file instanceof File ? URL.createObjectURL(file) : file);
    }
  },
  methods: {
    removeImage(index) {
      this.$emit('removeAt', index);
    },
    add(e) {
      const[file] = e.target.files;
      this.$emit('add', file)
    }
  }
}
</script>

<style scoped>
  .remove-image {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
  }
  .block-item .remove-image:hover {
    opacity: 1;
  }
  .blocks-container {
    display: flex;
  }
  .block-item {
    position: relative;
    width: 200px;
    height: 130px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    margin-right: 5px;
  }
  .block-item:hover .remove-image {
    opacity: 0.5;
  }
  .block {
    width: 200px;
    height: 130px;
    border-radius: 5px;
    border: 2px dashed #bebebe;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #input-file {
    display: none;
  }
</style>