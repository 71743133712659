<template>
  <b-form>
    <b-row>
      <b-col md="12">
        <b-form-group label="Пункт">
          <b-form-input v-model="form.point" placeholder="Пункт" :state="formErrors.point"/>
        </b-form-group>
        <b-form-group label="Заголовок">
          <b-form-input v-model="form.title" placeholder="Заголовок" :state="formErrors.title"/>
        </b-form-group>
        <b-form-group label="Подзаголовок">
          <b-form-input v-model="form.subtitle" placeholder="Подзаголовок" :state="formErrors.subtitle"/>
        </b-form-group>
        <b-form-group label="Таймзона" class="timezone-input-group">
          <b-form-input v-model="form.timezone" placeholder="Таймзона" :state="formErrors.timezone"/>
          <ul class="input-tooltip" v-if="timezonesTooltip.length">
            <li v-for="(timezone, index) in timezonesTooltip" :key="index" @click="setTimezone(timezone)">{{timezone}}</li>
          </ul>
        </b-form-group>
        <b-form-group label="Элементы">
          <div style="display: inline-block">
            <b-button variant="success" class="mb-3 w-100" @click="addItem">+</b-button>
          </div>
          <div class="list-group">
            <div class="list-group-item" v-for="(item, index) in form.items" :key="index">
              <b-row>
                <b-col md="3">
                  <b-form-group label="Элемент">
                    <b-form-input v-model="item.key" placeholder="Элемент"/>
                  </b-form-group>
                </b-col>
                <b-col md="9">
                  <b-form-group label="Значение">
                    <b-form-input v-model="item.value" placeholder="Значение"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-icon class="action-icon m-1 remove-icon-style" icon="x-lg" @click="removeItem(index)"></b-icon>
            </div>
          </div>
        </b-form-group>
        <b-form-group label="Изображения">
          <AddImages :max-images="2" :files="form.photos" @add="addImage" @removeAt="removeAt"/>
        </b-form-group>
        <b-form-checkbox v-model="form.isActive">
          Активность
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import validate from "@/utils/formValidator"
import STATION from "@/forms/STATION"
import AddImages from '@/components/ui/file/AddImages'
import api from '@/api/dictionaries.api'

const STATION_INIT_FORM = () => ({
  point: '',
  title: '',
  subtitle: '',
  timezone: '',
  items: [],
  photos: [],
  isActive: false
})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => STATION_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  mounted() {
    api.getTimezones().then(request => {
        console.log(request)
        this.timezones = request.data
    })
  },
  data() {
    return {
      form: this.init,
      errors: {},
      timezones: []
    }
  },
  watch: {
    form: {
      handler: function (value) {
        this.validateForm();

        if (this.timezones.indexOf(value.timezone) === -1) {
          this.errors.timezone = 'Необходимо заполнить таймзону'
        } else {
          this.errors.timezone = ''
        }
      },
      deep: true
    },
    timezones: function () {
      const timezone = this.form.timezone;
      this.form.timezone = '';
      this.form.timezone = timezone;
    }
  },
  computed: {
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    },
    timezonesTooltip: function() {
      let zone = this.form.timezone

      if (this.timezones.indexOf(zone) !== -1) {
        return []
      }

      if (!zone) {
        return []
      }

      return this.timezones.filter(function(timezone) {
        return timezone.toLowerCase().includes(zone.toLowerCase())
      }).splice(0,10)
    }
  },
  methods: {
    setTimezone(timezone) {
      this.form.timezone = timezone
    },
    addImage(file) {
      this.form.photos.push(file);
    },
    removeAt(index) {
      this.form.photos.splice(index, 1);
    },
    addItem() {
      this.form.items.push({
        key: '',
        value: ''
      })
    },
    removeItem(idx) {
      this.form.items.splice(idx, 1);
    },
    fillForm(form) {
        Object.assign(this.form, form)
    },
    resetForm() {
      Object.assign(this.form, STATION_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, STATION)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      if (!this.validateForm())
        return

      this.$emit('onSave', this.form);
    }
  },
  components: {
    AddImages
  }
}
</script>

<style scoped>
  .file__img {
    width: 300px;
    height: auto;
    margin-bottom: 20px;
  }
  .remove-icon-style {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .timezone-input-group {
    position: relative;
  }
  .input-tooltip {
    position: absolute;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0 0;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    z-index: 2;
  }
  .input-tooltip li {
    list-style: none;
    padding: 10px 0;
    cursor: pointer;
  }
</style>